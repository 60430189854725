<template>
  <div class="questions">
    <div class="questions_h">
      <div class="questions_top">
        <van-image
          fit="cover"
          :src="require('@/assets/img/PolicyLibrary_05.jpg')"
        />
      </div>
      <div class="questions_bottom">
        <div class="questions_info">
          <van-field
            v-model="questions.userName"
            label="申请人姓名"
            input-align="right"
            placeholder="请输入"
          />
          <van-field
            v-model="questions.question"
            rows="1"
            autosize
            label="您的问题"
            type="textarea"
            placeholder="请写明发生的问题/对我们的意见，便于我们提供更好的帮助"
          />
          <div class="questions_but">
            <van-button type="info" @click="questionsSubmit">下一步</van-button>
          </div>
        </div>
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage, Field, Button } from "vant";
import { addQuestion } from "@/api/policyLibrary";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Field.name]: Field,
    [Button.name]: Button,
    copyrightIp,
  },
  data() {
    return {
      questions: {
        userName: "", // 名字
        question: "", // 问题
        fromType: "1", //  (0:联系我们  1粉丝提问)
        openId: "",
      },
    };
  },
  created() {
    this.questions.openId =
      localStorage.getItem("code") || "oE1PV5krMUmcVe6GLqHcKX8s-Tj8";
  },
  methods: {
    questionsSubmit() {
      addQuestion(this.questions).then((res) => {
        if (res.data.data == 1001) {
          this.$router.push({
            path: "/policyLibrary/Result",
            query: {
              state: 1,
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.questions {
  height: 100%;
  .questions_h {
    background: #fff;
    min-height: calc(100% - 44px);
    .questions_top {
      .van-image {
        width: 100%;
      }
    }
  }
}

.questions_bottom {
  width: 100%;
  position: relative;
  .questions_info {
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0;
    border-radius: 22px 22px 0 0;
    overflow: hidden;
    background: #fff;
    padding-top: 10px;
    .questions_but {
      width: 100%;
      padding: 30px 10px 10px;
      .van-button {
        width: 100%;
      }
    }
  }
}
</style>
